<template>
    <div class="salesContractMonth">
        <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
        >
            <el-menu-item index="1">
                按销售区域
            </el-menu-item>
            <el-menu-item index="2">
                按合同类型
            </el-menu-item>
            <el-menu-item index="3">
                按业务员
            </el-menu-item>
        </el-menu>
        <salesArea v-if="activeIndex == 1"></salesArea>
        <typeOfContract v-if="activeIndex == 2"></typeOfContract>
        <salesman v-if="activeIndex == 3"></salesman>
        <!-- <salesContractComp v-if="showContract" :alert-status="alertStatus" @closeChildAlert="closeChildFn"></salesContractComp> -->
    </div>
</template>

<script>
// import addBgImages from './utils';
import salesArea from './components/monthDataAnalysis/salesArea';
import typeOfContract from './components/monthDataAnalysis/typeOfContract';
import salesman from './components/monthDataAnalysis/salesman';
export default {
    data() {
        return {
            activeIndex: '1',
        };
    },
    components: {
        salesArea,
        typeOfContract,
        salesman,
    },
    methods: {
        handleSelect(key) {
            this.activeIndex = key;
        },
    },
};

</script>
<style lang='stylus'>
.salesContractMonth
    height 100%
    display flex
    flex-direction column
    .el-menu
        border-bottom none
        padding .2rem
        padding-top .1rem
        padding-bottom 0
        .el-menu-item
            padding 0 .01rem
            height .4rem
            line-height .4rem
            margin-right .2rem
            color #333333
            font-size .16rem
        .is-active
            color #D6000F
            border-color #D6000F
.salesContractMonthBox
    height 100%
    display flex
    padding .2rem
    flex-direction column
    background #fff
    overflow-y auto
    .el-table__fixed-right thead tr th
        &:last-child
            width 1rem
            background #F5F2ED !important
            color #823802 !important
            border-left 1px solid #000
        &:nth-last-child(2)
            width 1rem
            background #F5F2ED !important
            color #823802 !important
            border-left 1px solid #000
    .el-table__fixed-body-wrapper tbody tr td
        &:last-child
            color #C9661E !important
        &:nth-last-child(2)
            color #C9661E !important
        &:nth-last-child(3)
            color #C9661E !important
    .el-table__fixed-footer-wrapper table tbody tr
        td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
                font-size .16rem
    .el-table__footer-wrapper table tbody td
        background none
        color #C9661E
    .top-echarts-two
        // flex 1
        width 100%
        overflow hidden
        // height 6rem
        height 3.6rem
        .echarts-total
            height: 100%;
            width calc(50% - .1rem)
            // width 100%
            // height 50%
            float left
            padding .2rem
            border 1px solid #D7D7D7
            box-sizing border-box
            .title-box
                display flex
                justify-content space-between
                flex 1
                h1
                    color #333333
                    font-size .18rem
                    &:before
                        content '`'
                        width .06rem
                        height .2rem
                        background #4D7DE0
                        color #4D7DE0
                        margin-right .1rem
                span
                    color #1577D2
                    height .25rem
                    padding 0 .2rem;
                    font-size .14rem
                    cursor pointer
                    border 1px solid #1577D2
        .echarts-left
            margin-right .2rem
    .table-list
        margin-top .1rem
        width 100%
        height calc(100% - 3.6rem)
        padding-bottom .2rem
        .el-table
            // overflow: auto;
            // &:after
            //     position:relative !important;
            // .el-table__header
            //     border-collapse: collapse;
            // .el-table__body-wrapper,
            // .el-table__header-wrapper,
            // .el-table__footer-wrapper
            //     overflow: visible;
            .el-table__header
                border-collapse: collapse;
            thead tr th
                color #022782
                font-size .16rem
                text-align center
                padding 0
            tbody tr td
                height .4rem
                text-align center
                padding 0
                font-size .14rem
    .export
        margin-top .1rem
        height .35rem
        .export_btn
            border: none;
            height: 0.36rem;
            font-size: 0.14rem;
            min-width: 0.36rem;
            line-height: 0.36rem;
            cursor: pointer;
            padding: 0 0.2rem;
            border-radius: 2px;
            background: #f1f4f7;
            color: #2978ff;
            text-align: center;
</style>
